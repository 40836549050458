import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PostLink from "./postLink.js"

export default function ArticleList(){
    return (
        <StaticQuery
            query={graphql`
                query {
                  allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }, filter: {frontmatter: {published: {eq: true},  type: {eq: "project"}}}) {
                    edges {
                      node {
                        id
                        excerpt(pruneLength: 250)
                        frontmatter {
                          date(formatString: "MMMM DD, YYYY")
                          slug
                          title
                        }
                      }
                    }
                  }
                }
              ` 
            }
            render={data => (
                <ul>
                    {data.allMarkdownRemark.edges
                    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
                    .map(edge => <PostLink key={edge.node.id} post={edge.node}/>)
                    }
                </ul>
                
            )}
        />
        
  )
}