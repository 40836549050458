import React from "react"
import Container from "../components/container.js"
import ArticleList from "../components/articleList.js"
import ProjectList from "../components/projectList.js"
import { Link } from "gatsby" // highlight-line

export default function Articles() {
  return (
    <Container>
        <Link to="/">&lt;&lt; home</Link>
        <h3>Articles</h3>
        <ArticleList/>
        <h3>Projects</h3>
        <ProjectList/>
    </Container>
  )
}
